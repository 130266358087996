import FormatUtils from './format.utils';
import AnimationsUtils from './animations.utils';
import ValidityUtils from './validity.utils';

const BattlesUtils = {
  ...FormatUtils,
  ...AnimationsUtils,
  ...ValidityUtils,
};

export default BattlesUtils;
