const createShortUrl = (url: string, startSlice = 13, endSlice = 7) => {
  const BETWEEN_SYMBOLS = '...';

  const normalisedUrl = url.toLowerCase().trim();

  const urlStartSlice = normalisedUrl.slice(0, startSlice);
  const urlEndSlice = normalisedUrl.slice(-1 * endSlice);

  const shortUrl = urlStartSlice + BETWEEN_SYMBOLS + urlEndSlice;

  return {
    original: url,
    short: shortUrl,
  };
};

export default {
  Format: {
    createShortUrl,
  },
};
