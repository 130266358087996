import type { Ref } from 'vue';

const createNumberValueConfig = (variableRef: Ref<number>, round?: number) => (valueTo: number) => ({
  duration: 1000,
  easing: 'easeOutExpo',
  round,
  targets: variableRef,
  /* value prop in ref variable */
  value: valueTo,
});

export default {
  Animations: {
    createNumberValueConfig,
  },
};
